<template>
  <v-layout row wrap>
    <v-flex class="col-timeline" v-bind="columnWidth.timeline" >
      <v-card
        ref="timelineContainer"
        class="card-timeline pl-3 pr-3"
        :class="{
          'card-timeline__compacted': timeLineView === 0,
          'card-timeline__extended': timeLineView === 2
        }"
      >
        <timeLine
          ref="timeline"
          :hits="timeSeries"
          :headers="headersTimeLine"
          :focused="focus"
          v-on:click="highlight"
          v-on:view-type-change="viewTypeChange"
        />
        <div
          v-if="!lastPage"
          class="loader-timeline"
          v-intersect.quiet="onIntersect"
        >
          <template v-if="loading">
            {{ $t('misc.loading') }}
            <v-progress-linear
              color="primary"
              indeterminate
              rounded
              height="6"
            />
          </template>
          <template v-else>
            <div class="timeline-down" v-on:click="loadNextPage">
              <div class="timeline-down__info"> {{ $t('misc.loadPastEvents') }} </div>
              <v-icon>mdi-chevron-down</v-icon>
            </div>
          </template>
        </div>
      </v-card>
    </v-flex>
    <v-flex v-bind="columnWidth.map">
      <v-card>
        <Map
          ref="map"
          :height="height"
          :mapLayers="mapLayers"
          :headers="headersMap"
          :hits="mapHits"
          :loading="loading"
          :fullLoading="fullLoading"
          :linked="true"
          :autoZoom="true"
          :focusLink="true"
          v-on:click="highlight"
        />
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import Map from '@/components/Map.vue';
import timeLine from '@/components/crud/timeLine.vue';
import _ from '@/misc/lodash';
import dataAccess from '@/misc/dataAccess';
import { buildAllGeoObjects } from '@/misc/buildGeoObject';
import mapFieldMixin from '@/mixins/mapField';
import clearCacheMixin from '@/misc/clearCacheMixin';

export default {
  name: 'timeLineMapField',
  mixins: [
    mapFieldMixin,
    clearCacheMixin,
  ],
  components: {
    timeLine,
    Map,
  },
  props: {
    seriesData: {
      type: Object,
      default: () => ({}),
    },
    mapLayers: {
      type: Array,
      default: () => ([]),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    loading: false,
    timeSeries: [],
    timeMarkers: [],
    total: 0,
    focus: null,
    timeLineView: 1,
    visibleItems: null,
    fullLoading: false,
    fetchDataDebounce: () => {},
  }),
  computed: {
    /**
     * Get height from options or return default height
     */
    height() {
      return this.options.height ?? '800px';
    },
    /**
     * Compute headers with translation
     */
    headers() {
      return this.readFields.map((header) => ({
        text: this.$t(header.label),
        name: this.$t(header.label),
        ...header,
      }));
    },
    /**
     * Compute headers only for TimeLine
     */
    headersTimeLine() {
      return this.headers.filter(({ display }) => (display || []).includes('TimeLine'));
    },
    /**
     * Compute headers only for Map
     */
    headersMap() {
      return {
        [this.alias]: this.headers.filter(({ display }) => (display || []).includes('TimeLine-Map')),
      };
    },
    /**
     * Compute if it's last page to hide loader for infinite scroll
     */
    lastPage() {
      return this.total <= this.pagination.itemsPerPage * (this.pagination.page + 1);
    },
    /**
     * Compute width of colums for the differents timeLineView
     *
     * @see Timeline viewType property
     */
    columnWidth() {
      if (this.timeLineView === 0) {
        return { timeline: { xs2: true }, map: { xs10: true } };
      }
      if (this.timeLineView === 2) {
        return { timeline: { xs4: true }, map: { xs8: true } };
      }
      return { timeline: { xs3: true }, map: { xs9: true } };
    },
    /**
     * Filter map hits to see only marker for visible hits in timeline
     * this.visibleItems contains id of first visible hit, it's updated on scrolle
     *
     * @see handleScroll function for more informations to visibleItems
     */
    mapHits() {
      let timeMarkers = [...this.timeMarkers];

      if (this.visibleItems !== null) {
        const firstIndex = this.timeMarkers.findIndex(
          ({ data }) => data.id === this.visibleItems,
        );

        timeMarkers = timeMarkers.slice(
          Math.max(0, firstIndex - 10),
          Math.min(timeMarkers.length, firstIndex + 15),
        );
      }
      return timeMarkers;
    },
    /**
     * Compute dataQuery with objectId and state of pagination
     */
    dataQuery() {
      return {
        id: this.objectId,
        page: this.pagination.page,
        limit: this.pagination.itemsPerPage,
      };
    },
  },
  watch: {
    /**
     * When options change, update the pagination if configuration are defined
     */
    options: {
      handler(options) {
        if (typeof options.pagination === 'object') {
          this.pagination = { ...this.pagination, ...options.pagination };
        }
      },
      immediate: true,
    },
    /**
     * When the focus changes, update the focus state of the hits
     */
    focus(focus) {
      this.timeHits = this.timeSeries.map((hit) => (
        { ...hit, focus: hit.id === focus }
      ));
      this.timeMarkers = this.timeMarkers.map((hit) => (
        { ...hit, focus: hit.data.id === focus }
      ));
    },
    /**
     * Fetch data when dateQuery are updated
     */
    dataQuery: {
      handler() {
        this.$nextTick(() => {
          this.fetchDataDebounce();
        });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.fetchDataDebounce = _.debounce(() => {
      this.fetchData();
    }, 500);

    // Debounce with 100ms wait between each call
    // (100ms is the mean for get reactive sensation on scroll)
    this.debouncedScroll = _.debounce(this.handleScroll(), 100);
    this.$refs.timelineContainer.$el.addEventListener('scroll', this.debouncedScroll);
  },
  beforeDestroy() {
    this.$refs.timelineContainer.$el.removeEventListener('scroll', this.debouncedScroll);
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.$nextTick(async () => {
        if (!_.isEmpty(this.dataQuery)) {
          const result = await this.$store.dispatch('crud/SEARCH', {
            object: this.object,
            alias: this.alias,
            body: this.dataQuery,
          });

          const hits = (result.body || [])
            .filter(({ current }) => !!current)
            .sort((a, b) => (a.order - b.order));

          this.timeSeries = this.computeSeries(hits);
          this.timeMarkers = await buildAllGeoObjects(this.timeSeries, this.geoHeader);
          if (Array.isArray(this.timeMarkers)) {
            this.timeMarkers = this.timeMarkers.filter((timeMarker) => timeMarker);
          }
          this.total = result.totalResult;
        }

        this.$nextTick(() => {
          this.loading = false;
          setTimeout(() => {
            this.fullLoading = false;
            // We force at 500ms the timeout to let time to the frontend to load and filter
            // data to display
          }, 500);
        });
      });
    },
    /**
     * Compute hits for specific data like travel and issues status
     *
     * @return {Record<string, unknown>[]}
     */
    computeSeries(hits) {
      const series = [...this.timeSeries, ...hits];

      let activeIssues = [];
      const seriesLength = series.length - 1;
      const end = Math.max(0, seriesLength - 20);

      // Check issues only if seriesData config is defined
      const checkIssue = typeof this.seriesData.issueEvent !== 'undefined'
        && typeof this.seriesData.issueId !== 'undefined';

      for (let i = seriesLength; i >= end; i -= 1) {
        const hit = series[i];
        // Check if moving contains travel to handle ENTER_TRAVEL|EXIT_TRAVEL|TRAVEL
        hit.travel = (hit.moving || '').includes('TRAVEL');

        if (checkIssue) {
        /**
         * Logic for issues
         */
          const issueEvent = dataAccess.get(hit, this.seriesData.issueEvent, null);
          const issueId = dataAccess.get(hit, this.seriesData.issueId, null);

          if (issueEvent === 'triggered' && issueId !== null) {
            activeIssues.push(issueId);
          }
          const inIssue = activeIssues.findIndex((e) => typeof e !== 'undefined') >= 0;
          hit.issueEvent = issueEvent;
          hit.issueId = issueId;
          if (inIssue) {
            hit.activeIssues = [...activeIssues];
          }
          if (issueEvent === 'closed' && activeIssues.includes(issueId)) {
            delete activeIssues[activeIssues.indexOf(issueId)];
            // Trim left empty values in activeIssues (keep undefined between values to keep order)
            const lastValue = [...activeIssues].reverse().find((e) => e !== undefined);
            activeIssues = activeIssues.slice(0, activeIssues.indexOf(lastValue) + 1);
          }
        }
      }

      return series;
    },
    /**
     * Create function to handle the scroll on timeline container
     *
     * @return {Function}
     */
    handleScroll() {
      let { offsetHeight: containerHeight } = this.$refs.timelineContainer.$el;
      // Subtract top margin to get real container Height
      const { offsetTop: offsetTimeline } = this.$refs.timeline.$el.querySelector('.v-timeline');
      containerHeight -= offsetTimeline;

      return ({ target }) => {
        const children = target.querySelectorAll('.row-timeline:not(.item-travel):not(.item-hole)');

        let visible = null;
        // Iterate on each row-timeline and stop when first visible had found
        for (let i = 0; i < children.length && visible === null; i += 1) {
          const node = children[i];
          let { offsetTop } = node;
          if (offsetTop === 0) {
            offsetTop = node.parentNode.offsetTop;
          }

          const distance = (offsetTop - target.scrollTop);
          if ((distance >= 1 && distance <= containerHeight)
          && node.__vue__ && node.__vue__.hit) {
            visible = node.__vue__.hit.id;
          }
        }
        this.visibleItems = visible;
      };
    },
    /**
     * Update focus state, when hit is clicked on components
     */
    highlight(id) {
      if (this.focus === id) {
        this.focus = null;
      } else {
        this.focus = id;
      }
    },
    viewTypeChange(value) {
      this.timeLineView = value;
    },
    loadNextPage() {
      this.pagination.page += 1;
      this.fullLoading = true;
    },
    /**
     * Call when loader is visible (end of list)
     *
     * @param {IntersectionObserverEntry[]} entries List of IntersectionObserverEntry objects
     */
    onIntersect(entries) {
      if (entries[0].isIntersecting && !this.lastPage) {
        this.loadNextPage();
      }
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math' as *;

.col-timeline {
  position: relative;
}
.card-timeline {
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.18);
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.30);
  }
}

.loader-timeline {
  position: relative;
  padding-top: 20px;
  text-align: center;
  transition: opacity ease .3s;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(35% - 1px);
    right: initial;
    bottom: 0;
    width: 2px;
    background-image: linear-gradient(0deg,
      rgba(0, 0, 0, 0.12),
      rgba(0, 0, 0, 0.12) 75%,
      transparent 75%,
      transparent 100%);
    background-size: 2px 14px;
    background-repeat: repeat-y;
  }
}
.timeline-down {
  width: 35%;
  cursor: pointer;
  &__info {
    text-align: center;
  }
  .v-icon {
    font-size: 32px;
  }
}
.card-timeline__compacted {
  .loader-timeline::before {
    left: calc(100% - 27px);
  }
  .timeline-down {
    width: calc(100% - 42px);
  }
}
.card-timeline__extended {
  .loader-timeline::before {
    left: calc(25% - 1px);
  }
  .timeline-down {
    width: calc(25% - 20px);
  }
}
</style>
