<template>
  <div class="timeline-container">
    <div class="timeline-config">
      <v-layout>
        <v-menu
          v-model="visibleFieldsOpen"
          :close-on-content-click="false"
          offset-y
          v-if="showMenu"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              small
              v-on="on"
            >
              <v-icon>list</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-2">
            <v-switch
              v-for="field in visibleFields"
              :key="field.fid"
              v-model="field.visible"
              :label="$t(field.label)"
              dense
              :hide-details="true"
              style="margin-top: 0"
            />
          </v-card>
        </v-menu>
        <v-spacer v-if="showMenu"/>
        <v-btn-toggle v-model="viewType" mandatory>
            <v-btn small>
              <v-icon>mdi-view-stream</v-icon>
            </v-btn>
            <v-btn small>
              <v-icon>mdi-view-split-vertical</v-icon>
            </v-btn>
            <v-btn small>
              <v-icon>mdi-view-list</v-icon>
            </v-btn>
        </v-btn-toggle>
      </v-layout>
    </div>
    <v-timeline class="body-2 mb-5" :class="classTimeLine" >
      <section
        v-for="(group, date) in computedHits"
        :key="date"
      >
        <template
          v-for="(dataHit, row) in group.hits"
        >
          <div
            :key="row"
            v-if="dataHit._sub"
            class="group-travel"
            :class="{ 'open': travelOpen.includes(row) }"
          >
            <time-line-item
              v-show="!travelOpen.includes(row)"
              class="item-travel"
              :viewType="viewType"
              v-bind="dataHit"
              @click="clickTravel(row)"
            />
            <template v-for="(subHit, subRow) in dataHit._sub">
              <time-line-item
                v-show="travelOpen.includes(row)"
                :ref="subHit.hit.id"
                :key="subRow"
                :visibleFields="visibleFields"
                :viewType="viewType"
                v-bind="subHit"
                @click="click"
                @click-out="clickTravel(row)"
              />
            </template>
          </div>
          <time-line-item
            v-else
            :ref="dataHit.hit.id"
            :key="row"
            :visibleFields="visibleFields"
            :viewType="viewType"
            v-bind="dataHit"
            @click="click"
          />
        </template>
        <div
          v-if="group.day !== null"
          class="row-date"
        >
          <format-data
            :value="group.day.value"
            :format="group.day.format"
          />
        </div>
      </section>
    </v-timeline>
  </div>
</template>

<script>
import parseJSON from 'date-fns/parseJSON';
import getDayOfYear from 'date-fns/getDayOfYear';
// import differenceInSeconds from 'date-fns/differenceInSeconds';
import timeLineItem from '../timeLine/timeLineItem.vue';
import FormatData from './FormatData.vue';
import dataAccess from '../../misc/dataAccess';
import _ from '../../misc/lodash';

/**
 * Interface for v-timeline component, support grouping by day and by travel
 */
export default {
  name: 'timeLine',
  components: {
    FormatData,
    timeLineItem,
  },
  props: {
    headers: {
      type: [Object, Array],
      default: () => ([]),
    },
    hits: {
      type: Array,
      default: () => ([]),
    },
    focused: {
      type: String,
      default: null,
    },
    relativeDate: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    page: 1,
    currentFocused: [],
    keyPref: '',
    visibleFieldsOpen: false,
    visibleFields: {},
    viewType: 1,
    travelOpen: [],
  }),
  computed: {
    /**
     * Compute the hits
     * parse the data group by day for relativeDate and group by travel
     *
     * @return {Record<string, unknown>[]} Return array of computed hits grouped
     */
    computedHits() {
      const groups = [];
      const hitsLength = this.hits.length;
      let currentDay = 0;
      let currentGroup = 0;
      let groupWith = false;

      for (let i = 0; i < hitsLength; i += 1) {
        const hit = this.hits[i];
        const parsedData = this.parseData(hit);
        const dataRow = {
          hit,
          // Need repeat issues data for more reactivity
          activeIssues: hit.activeIssues,
          isFocused: this.currentFocused === hit.id,
          issue: hit.issueId !== null,
          clickable: hit.issueId === null,
          ...parsedData,
        };

        if (this.relativeDate) {
          const dayOfYear = getDayOfYear(dataRow.date);
          if (currentDay !== dayOfYear && currentDay !== 0) {
            groupWith = false;
            currentGroup += 1;
            currentDay = dayOfYear;
          } else {
            currentDay = dayOfYear;
          }
        }

        if (typeof groups[currentGroup] === 'undefined') {
          groups[currentGroup] = {
            hits: [],
            day: null,
          };

          if (dataRow.date !== null && this.relativeDate) {
            groups[currentGroup].day = {
              value: dataRow.date,
              format: {
                type: 'date',
                template: 'dd MMMM yyyy',
              },
            };
          }
        }

        let hole = false;
        if (dataRow._hole !== false) {
          let range = false;
          if (dataRow._hole.info.time_since_last_state) {
            const delay = dataRow._hole.info.time_since_last_state.value;
            const currentDate = new Date();
            const otherDate = currentDate.getTime() - delay * 1000;
            range = [currentDate, new Date(otherDate)];
          }
          const resultValue = 'waiting';
          hole = {
            hole: true,
            hit,
            // Need repeat issues data for more reactivity
            activeIssues: hit.activeIssues,
            card: {
              title: {
                value: resultValue,
                format: {
                  type: 'localized',
                  prefix: 'gap',
                },
              },
            },
            icon: {
              value: 'mdi-timer-sand',
              format: {
                type: 'icon',
              },
            },
          };

          if (range !== false) {
            hole.data = [{
              value: range.map((date) => (parseJSON(date))),
              format: { type: 'timeDistance' },
            }];
          }
        }

        if (dataRow.travel) {
          if (groupWith === false) {
            groups[currentGroup].hits.push({
              hole: true,
              clickable: true,
              // Need repeat issues data for more reactivity
              activeIssues: hit.activeIssues,
              card: {
                title: {
                  value: 'travel',
                  format: {
                    type: 'localized',
                    prefix: 'misc', // t
                  },
                },
              },
              icon: {
                value: 'mdi-truck-fast',
                format: {
                  type: 'icon',
                },
              },
              _sub: [],
            });
            groupWith = groups[currentGroup].hits.length - 1;
          }
          groups[currentGroup].hits[groupWith]._sub.push({
            ...dataRow,
            groupWith,
          });
          if (hole !== false) {
            groups[currentGroup].hits[groupWith]._sub.push(hole);
          }
        } else {
          groupWith = false;
          groups[currentGroup].hits.push(dataRow);
          if (hole !== false) {
            groups[currentGroup].hits.push(hole);
          }
        }
      }

      return groups;
    },
    /**
     * Compute actives classes of timeline
     *
     * @return {Record<string, boolean>} Return object with classes names and boolean if actives
     */
    classTimeLine() {
      return {
        'compacted-timeline': this.viewType === 0,
        'normal-timeline': this.viewType === 1,
        'extended-timeline': this.viewType === 2,
        'relativeTime-timeline': this.relativeDate,
      };
    },
    /**
     * Get userPreferences from Auth store
     *
     * @return {Record<string, unknown>} Return object of userPreferences
     */
    userPreferences() {
      const preferences = this.$store.state.auth.currentUserffly4u._source.timeLinePreferences
        || {};
      return preferences[this.keyPref] || {};
    },
    /**
     * Toggle timeline menu visibility base if there is item in the menu or not
     *
     * @return {Boolean} Return false if there is no menu item
     */
    showMenu() {
      return !_.isEmpty(this.visibleFields);
    },
  },
  watch: {
    /**
     * Update state currentFocused and scroll timeline to focused hit
     * when props focused change
     *
     * @param {string|number} focused key of hit focused
     */
    focused(focused) {
      // Watch only external change
      if (this.currentFocused !== focused) {
        this.currentFocused = focused;
        if (this.$refs[focused] && this.$refs[focused][0] && this.$refs[focused][0].$el) {
          const { groupWith } = this.$refs[focused][0];
          // Open travel if is not open
          if (!this.travelOpen.includes(groupWith)) {
            this.travelOpen.push(groupWith);
          }

          this.$nextTick(() => {
            this.$refs[focused][0].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          });
        }
      }
    },
    /**
     * Emit id of selected hits
     */
    currentSelected() {
      this.$emit('selected', this.currentSelected.map((sel) => ({ id: sel.id })));
    },
    /**
     * Update preferences when visibleFields change
     */
    visibleFields: {
      /**
       * @param {Record<string, any>} value Object of fields currently visible
       * @param {Record<string, any>} prevValue Object of fields previously visible
       */
      handler(value, prevValue) {
        // Check if prevValue is empty to prevent the first call at created Hook
        if (!_.isEmpty(prevValue)) {
          const visibleFields = {};
          Object.entries(value).forEach(([fid, { visible }]) => {
            visibleFields[fid] = visible;
          });
          this.updatePreferences({ visibleFields });
        }
      },
      deep: true,
    },
    /**
     * Emit the new viewType to parent and update preferences when is changed
     *
     * @param {number} value Id of current viewType
     * @param {number} prevValue Id of previous viewType
     */
    viewType(value, prevValue) {
      this.$emit('view-type-change', value, prevValue);
      this.updatePreferences({ viewType: value });
    },
  },
  /**
   * Get preferences and compute visible fields and viewType on created
   */
  created() {
    let currentPath = this.$router.currentRoute.matched[0].path;
    if (currentPath.length === 0) {
      currentPath = 'home';
    }
    this.keyPref = currentPath;

    this.visibleFields = this.buildVisibleFields(this.headers.filter(
      ({ slot }) => (typeof slot === 'undefined'),
    ));
    this.viewType = this.userPreferences.viewType;
  },
  methods: {
    /**
     * Emit id of hit clicked to parent
     *
     * @param {Record<string, unknown>} item Receive an hit to get id
     */
    click(item) {
      if (item && typeof item.id !== 'undefined') {
        this.currentFocused = item.id;

        const emptyCoords = { latitude: null, longitude: null, radius: null };
        if (!_.isEqual(item.coords, emptyCoords)) {
          this.$emit('click', item.id);
        }
        // else {
        //   console.log('item without coords (position) clicked', item);
        // }
      } else {
        throw new Error('Item doesn\'t have id');
      }
    },
    /**
     * Toggle state of travel if is closed or open
     *
     * @param {number} key index of hits in group.hits
     */
    clickTravel(key) {
      const index = this.travelOpen.indexOf(key);
      if (index !== -1) {
        this.travelOpen.splice(index, 1);
      } else {
        this.travelOpen.push(key);
      }
    },
    /**
     * Parse hit data to dataRow
     *
     * data:
     *```
     *{
     *  value: number | string | Record<string, unknown>,
     *  format: Record<string, unknown> | undefined
     *}
     *```
     *
     * field extend data:
     *```
     *{
     *  fid: string,
     *  label: string,
     *}
     *```
     *
     * dataRow:
     *```
     *{
     *  card: { title: data | null, caption: data | null },
     *  data: field[],
     *  icon: data | undefined,
     *  date: Date,
     *  important: boolean,
     *  time: data,
     *  _hole: boolean | data,
     *}
     *```
     *
     * @param {Record<string, unknown>} hit raw hit to parse
     *
     * @return {Record<string, unknown>} Return an dataRow
     */
    parseData(hit) {
      const dataRow = {
        _hole: false,
        data: [],
        travel: false,
        important: false,
        confident: hit.confident,
      };

      if (hit.interState > 0
        && !_.isEmpty(hit.interStateInfo)
        && hit.generationTimestamp) {
        dataRow._hole = {
          generationTimestamp: hit.generationTimestamp,
          info: hit.interStateInfo,
          value: hit.interState,
        };
      }
      for (let h = 0; h < this.headers.length; h += 1) {
        const header = this.headers[h];
        const value = dataAccess.get(hit, header.path, '');

        // If header define isImportant condition define if dataRow is important
        if (header.isImportant) {
          dataRow.important = _.validate(
            header.isImportant.type,
            header.isImportant.testValue,
            value,
          );
        }

        // Switch for slot
        switch (header.slot) {
          case 'title':
          case 'caption':
            // For first title or caption create card object
            if (typeof dataRow.card === 'undefined') {
              dataRow.card = {
                title: null,
                caption: null,
              };
            }

            // Save card data slot
            if (dataRow.card[header.slot] === null && value !== '') {
              dataRow.card[header.slot] = {
                value,
                format: header.format,
              };
            }
            break;
          case 'icon-event':
            if (typeof dataRow.icon === 'undefined' && value !== '') {
              dataRow.icon = {
                value,
                format: header.format,
              };
            }

            break;
          case 'color-event':
            const options = {
              alias: header.options.aliasColors,
              defaultValue: header.options.defaultColor,
            };
            dataRow.color = dataAccess.getAliasValue(options, value);
            break;
          case 'time':
            const date = new Date(Number(value));
            dataRow.date = !Number.isNaN(date.getTime()) && date;
            dataRow.time = {
              value,
              format: header.format,
            };
            break;
          case 'travel':
            dataRow.travel = value === 'TRAVEL';
            break;
          default:
            dataRow.data.push({
              fid: header.fid,
              label: header.label,
              value,
              format: header.format,
            });
            break;
        }
      }
      return dataRow;
    },
    /**
     * Build object of visible fields
     *
     * Return:
     *```
     *{
     *  fid: field
     *  ...
     *}
     *```
     *
     * @param {Record<string, unknown>[]} headers header of field
     *
     * @return {Record<string, field>} Return object key => value of fields with state visible
     */
    buildVisibleFields(headers) {
      const userFields = this.userPreferences.visibleFields || {};
      const visibleFields = {};
      headers.forEach(({ fid, label, enabledData }) => {
        let visible = enabledData || false;

        if (typeof userFields[fid] !== 'undefined') {
          visible = userFields[fid];
        }

        visibleFields[fid] = {
          fid,
          label,
          visible,
        };
      });
      return visibleFields;
    },
    /**
     * Update the preferences of timeline
     * Merge new preferences with previous
     *
     * @param {Record<string, unknown>} preferences object of preferences
     */
    updatePreferences(preferences) {
      const userPreferences = this.$store.state.auth.currentUserffly4u._source.timeLinePreferences
        || {};
      this.$store.dispatch('auth/UPDATE_SELF', {
        timeLinePreferences: {
          ...userPreferences,
          [this.keyPref]: _.deepMerge(this.userPreferences, preferences),
        },
      });
    },
  },
};
</script>

<style lang="scss">
.timeline-container {
  position: relative;
}

.timeline-config {
  position: sticky;
  top: 0;
  z-index: 10;
  margin: 0 -12px;
  padding: 10px 12px;
  background-color: #ffffff;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),
    0 2px 2px 0 rgba(0,0,0,.14),
    0 1px 5px 0 rgba(0,0,0,.12);
}

.v-timeline-item {
  padding-top: 12px;
  padding-bottom: 12px;
}

.v-timeline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.relativeTime-timeline {
    padding-top: 0;
  }
  &:before {
    display: none;
  }
  .row-date {
    position: sticky;
    bottom: 20px;
    z-index: 4;
    max-width: calc(35% - 25px);
    font-size: 16px;
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: right;
    background: rgba(255,255,255, .7);
  }
  .v-timeline-item {
    position: relative;
    align-items: center;
    &__opposite {
      max-width: calc(35% - 25px);
      .content-opposite {
        display: inline-block;
      }
    }
    &__divider {
      min-width: 50px;
    }
    &__dot .v-icon {
      color: #ffffff;
      font-size: 16px;
    }
  }
  .row-timeline {
    &:last-child {
      .v-timeline-item::before {
        bottom: -40px;
      }
    }
    &.item-important .v-timeline-item__dot {
      background-color: #64B5F6;
      .v-icon.v-icon {
        font-size: 34px;
      }
    }
    &.item-delta .v-timeline-item__dot {
      display: none;
    }
    &.item-hole .v-timeline-item__dot {
      height: 40px;
      border-radius: 50% / 25%;
      .v-timeline-item__inner-dot {
        height: 34px;
        border-radius: 50% / 25%;
      }
    }
  }
  .row-date + .row-timeline,
  .row-date + .group-travel {
    .v-timeline-item__lines {
      bottom: -56px;
    }
  }
  section .row-timeline:last-child .v-timeline-item__lines {
      bottom: -10px;
  }
  .group-travel.group-travel {
    position: relative;
    &::before {
      content: '\F084D';
      font: normal normal normal 31px/1 "Material Design Icons";
      position: absolute;
      top: calc(50% - 16px);
      opacity: 0;
    }
    .row-timeline.item-travel .v-timeline-item {
      padding-top: 15px;
      padding-bottom: 30px;
      &::before {
        top: 0;
        bottom: 0;
        background: #d3d3d3;
      }
    }
    &.open {
      background-color: rgba(230, 230, 230, .4);
      transition: background-color ease .3s;
      &:hover {
        background-color: rgba(230, 230, 230, 1);
        &::before {
          transition: opacity ease-in .3s;
          opacity: 1;
        }
      }
      .row-timeline .v-timeline-item::before {
        background: linear-gradient(
          180deg,
          #d3d3d3,
          #d3d3d3 50%,
          transparent 50%,
          transparent 100%
        );
        background-size: 1px 20px;
      }
    }
  }
  /**
   * v-timeline-item_body need double selectors to increase
   * they specificity for override default style
   */
  &.v-timeline .row-timeline.row-timeline {
    .v-timeline-item.v-timeline-item {
      .v-timeline-item__body.v-timeline-item__body {
        max-width: calc(65% - 25px);
      }
    }
  }
}

// Style view type
.v-timeline {
  &.compacted-timeline.compacted-timeline {
    .row-date {
      max-width: calc(100% - 50px);
    }
    .v-timeline-item__opposite {
      max-width: calc(100% - 25px);
      width: 100%;
    }
  }
  &.extended-timeline.extended-timeline {
    .row-date {
      max-width: calc(25% - 25px);
    }
    .v-timeline-item__opposite {
      max-width: calc(25% - 25px);
    }
    .row-timeline.row-timeline {
      .v-timeline-item.v-timeline-item {
        .v-timeline-item__body.v-timeline-item__body {
          max-width: calc(75% - 25px);
        }
      }
    }
  }
}

.item-clickable {
  cursor: pointer;
}
</style>
