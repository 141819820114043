<script>
import { VTimelineItem } from 'vuetify/lib';

export default {
  name: 'f-timeline-item',
  extends: VTimelineItem,
  state: () => ({
    divLines: false,
  }),
  mounted() {
    // Slot lines is an div and doesn't have lines class
    if (this.divLines
      && !this.$slots.lines[0].elm.classList.contains('v-timeline-item__lines')) {
      this.$slots.lines[0].elm.classList.add('v-timeline-item__lines');
    }
  },
  methods: {
    genLines() {
      let children = [];
      if (this.$slots.lines && this.$slots.lines[0]) {
        // Div have data so direct return
        if (this.$slots.lines[0].data) {
          this.divLines = true;
          return this.$slots.lines;
        }
        // template return array of children
        children = this.$slots.lines;
      }
      return this.$createElement('div', {
        staticClass: 'v-timeline-item__lines',
      }, children);
    },
    // Overide genDivider to add genLines in children
    genDivider() {
      const children = [
        this.$createElement('div', {
          staticClass: 'v-timeline-item__lines main-line',
        }, [this.$createElement('div')]),
      ];

      if (!this.hideDot) children.push(this.genDot());

      return this.$createElement('div', {
        staticClass: 'v-timeline-item__divider',
      }, children);
    },
  },
  render(h) {
    const children = [
      this.genBody(),
      this.genDivider(),
    ];

    if (this.$slots.lines) children.unshift(this.genLines());

    if (this.$slots.opposite) children.push(this.genOpposite());

    return h('div', {
      staticClass: 'v-timeline-item f-timeline-item',
      class: {
        'v-timeline-item--fill-dot': this.fillDot,
        'v-timeline-item--before': this.timeline.reverse ? this.right : this.left,
        'v-timeline-item--after': this.timeline.reverse ? this.left : this.right,
        ...this.themeClasses,
      },
    }, children);
  },
};
</script>

<style lang="scss">
@use 'sass:math' as *;
// Need declare the variable in the root for use in view types
$arrowSize: 31px;

.v-timeline-item {
  &.f-timeline-item &__divider {
    position: static;
  }
  &__lines {
    position: absolute;
    top: 0;
    bottom: 0;
    div {
      height: 100%;
      width: 2px;
      background-color: var(--color-line, #7b7b7b);
    }
    &:not(.main-line) {
      display: flex;
      flex-direction: row;
      left: -15px;
      div {
        margin-left: 1px;
        margin-right: 1px;
        // Manage 6 lines in addition to principal line
        @for $i from 1 through 6 {
          &[data-order="#{$i}"] {
            position: absolute;
            left: #{$i * 6px};
          }
        }
        &.triggered {
          bottom: 40%;
        }
        &.closed {
          top: 40%;
        }
      }
    }
    &.main-line::after {
      content: '\F0143';
      font: normal normal normal $arrowSize/1 "Material Design Icons";
      position: absolute;
      // Quarter the size to center vertically
      bottom: -#{round($arrowSize/4) + 4px};
      // Half the size to center horizontally
      left: -#{round($arrowSize/2) - 1px};
      z-index: 1;
    }
  }
}
</style>
