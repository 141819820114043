<template>
  <div
    class="row-timeline"
    :class="classItem"
    @click="clickable && click($event, hit)"
  >
    <f-timeline-item
      :small="!important"
      :color="colorIcon"
    >
      <v-card
        v-if="viewType >= 1 && card"
        class="pa-2"
        v-bind="propsCard"
      >
        <v-layout>
          <v-flex
            :xs12="viewType==1"
            :xs6="viewType==2"
          >
            <div
              v-if="card.title"
              class="v-card-title"
            >
              <b>
                <format-data
                  :value="card.title.value"
                  :format="card.title.format"
                />
              </b>
            </div>
            <div
              v-if="card.caption || !confident"
              class="v-card-subtitle caption"
            >
              <span v-if="!confident">
                {{ $t(`misc.unconfidentLocalization.${clickable ? 'visible' : 'notVisible'}`) }}
              </span>
              <format-data
                v-else
                :value="card.caption.value"
                :format="card.caption.format"
              />
            </div>
            <div
              v-if="data.length >= 1"
              class="v-card-text font-weight-bold"
            >
              <div
                v-for="field in visibleDataFields"
                :key="field.fid"
              >
                {{ $t(field.label) }}:
                <format-data
                  :value="field.value"
                  :format="field.format"
                />
              </div>
            </div>
          </v-flex>
          <v-flex v-if="viewType === 2">
            <div
              v-for="field in hiddenDataFields"
              :key="field.fid"
            >
              {{ $t(field.label) }}:
              <format-data
                :value="field.value"
                :format="field.format"
              />
            </div>
          </v-flex>
        </v-layout>
      </v-card>
      <div
        slot="opposite"
        :class="classOpposite"
      >
        <format-data
          v-if="time"
          :value="time.value"
          :format="time.format"
          class="caption"
          :style="{ fontWeight: isFocused ? 600 : 400 }"
        />
        <template v-if="viewType === 0">
          <div v-if="card && card.title">
            <b>
              <format-data
                :value="card.title.value"
                :format="card.title.format"
              />
            </b>
          </div>
          <div v-if="card && card.caption">
            <format-data
              :value="card.caption.value"
              :format="card.caption.format"
            />
          </div>
        </template>
      </div>
      <template slot="icon">
        <format-data
          v-bind="icon"
        />
      </template>
      <div slot="lines">
        <div
          v-for="issue in issues"
          :key="`${hit.id}-${issue.order}`"
          :class="issue.event"
          :style="`--color-line: ${issue.color}`"
          :data-order="issue.order"
        />
      </div>
    </f-timeline-item>
  </div>
</template>

<script>
import FTimeLineItem from './FTimeLineItem.vue';
import FormatData from '../crud/FormatData.vue';

/**
 * Interface of v-timeline-item, handle click on item or click out
 * And separate the style and presentation of items of timeLine
 */
export default {
  name: 'timeLineItem',
  components: {
    'f-timeline-item': FTimeLineItem,
    FormatData,
  },
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
    hit: {
      type: Object,
      default: () => ({}),
    },
    card: {
      type: Object,
    },
    icon: {
      type: Object,
    },
    color: {
      type: String,
    },
    time: {
      type: Object,
    },
    delta: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    hole: {
      type: Boolean,
      default: false,
    },
    issue: {
      type: Boolean,
      default: false,
    },
    important: {
      type: Boolean,
      default: false,
    },
    isFocused: {
      type: Boolean,
      default: false,
    },
    visibleFields: {
      type: Object,
      default: () => ({}),
    },
    groupWith: {
      type: Number,
    },
    viewType: {
      type: Number,
      default: 1,
    },
    activeIssues: {
      type: Array,
      default: () => ([]),
    },
    confident: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    /**
     * Compute class of item
     *
     * @return {Record<string, boolean>} Return state of classes of item
     */
    classItem() {
      return {
        'item-delta': this.delta,
        'item-clickable': this.clickable,
        'item-hole': this.hole,
        'item-important': this.important,
      };
    },
    /**
     * Compute class of opposite
     *
     * @return {Record<string, boolean>} Return state of classes of opposite
     */
    classOpposite() {
      return {
        'content-opposite': true,
        'font-italic grey--text text--darken-1': this.delta || this.hole,
      };
    },
    /**
     * Computed color of icon container
     *
     * @return {string} Return color string of icon container
     */
    colorIcon() {
      if (typeof this.color !== 'undefined') {
        return this.color;
      }
      if (this.hole) {
        return 'grey';
      }
      if (this.issue) {
        return 'red';
      }
      return this.isFocused ? 'blue' : 'primary';
    },
    /**
     * Computed props of v-card
     *
     * output:
     *```
     *{
     *  elevation: number,
     *  color: string,
     *}
     *```
     *
     * @return {Record<string, unknown>} Return props of v-card
     */
    propsCard() {
      let color = ['blue', 'lighten-4'];
      if (this.confident === false) {
        color[0] = 'blue-grey';
      }
      if (this.hole) {
        color = ['grey', 'lighten-3'];
      } else if (this.isFocused) {
        color[1] = 'lighten-3';
      }
      return {
        elevation: this.isFocused ? 6 : 2,
        color: color.join(' '),
      };
    },
    /**
     * Compute lines objects for issues
     *
     * output:
     *```
     *[
     *  {
     *    order: number,
     *    color: string,
     *    event: string | boolean,
     *  }
     *]
     *```
     *
     * @return {Record<string, unknown>[]} Return an array of lineObject
     */
    issues() {
      const lineColors = ['BlueViolet', 'crimson', 'darkcyan', 'darkmagenta', 'darkslateblue', 'darkviolet'];
      return this.activeIssues.map((v, k) => ({
        order: k + 1,
        color: lineColors[k],
        event: this.hit.issueId === v ? this.hit.issueEvent : false,
      }));
    },
    /**
     * Computed visible data fields
     *
     * It's visible if exist in visibleFields an visible it's true
     *
     * @see methods `isvisibleField()`
     *
     * output:
     *```
     *{
     *  fid: string,
     *  format: Record<string, unknown>,
     *  label: string,
     *  value: string|number|object
     *}
     *```
     *
     * @return {Record<string, unknown>[]} Return visible data fields
     */
    visibleDataFields() {
      return this.data.filter(({ fid }) => this.isvisibleField(fid));
    },
    /**
     * Computed hidden data fields
     *
     * It's hidden if not exist in visibleFields or visible it's false
     *
     * @see methods `isvisibleField()`
     *
     * output:
     *```
     *{
     *  fid: string,
     *  format: Record<string, unknown>,
     *  label: string,
     *  value: string|number|object
     *}
     *```
     *
     * @return {Record<string, unknown>[]} Return hidden data fields
     */
    hiddenDataFields() {
      return this.data.filter(({ fid }) => !this.isvisibleField(fid));
    },
  },
  methods: {
    /**
     * Check if field is visible by fid
     *
     * @param {string} fid Field id
     *
     * @return {boolean} Return if field is visible or not
     */
    isvisibleField(fid) {
      return this.visibleFields[fid] && this.visibleFields[fid].visible;
    },
    /**
     * Emit click when data is clicked or clickOut when click out
     *
     * @param {MouseEvent} $event Event of click
     * @param {Record<string, unknown>} data Data of hit
     */
    click($event, data) {
      const classTarget = $event.target.classList;
      // Detect click out of elements
      if (classTarget.contains('v-timeline-item')
        || classTarget.contains('v-timeline-item__opposite')) {
        this.$emit('click-out');
      } else {
        this.$emit('click', data);
      }
    },
  },
};
</script>
