import _ from './lodash';

export default {
  beforeDestroy() {
    const objects = [];
    if (this.componentData) {
      let options = _.get(this.componentData, 'options', []);
      if (!Array.isArray(options)) {
        options = [options];
      }
      objects.push(...this.getObjects(options));

      if (this.mapLayers) {
        objects.push(...this.getObjects(this.mapLayers));
      }
    }
    // clear only if have objects are defined
    if (objects.length > 0) {
      this.$store.commit('crud/CLEAR_CACHE', objects);
    }
  },
  methods: {
    getObjects(layers) {
      const objects = [];
      for (let i = 0; i < layers.length; i += 1) {
        const layer = layers[i];
        objects.push(layer.alias || layer.object);
      }
      return objects;
    },
  },
};
