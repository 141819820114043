import baseFields from './baseFields';

export default {
  mixins: [
    baseFields,
  ],
  props: {
    mapLayers: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    geoHeader() {
      const geoHeader = this.readFields.find(
        ({ type }) => ['marker', 'shape', 'circle_marker'].includes(type),
      );
      if (typeof geoHeader !== 'undefined') {
        return {
          object: this.object,
          alias: this.alias,
          ...geoHeader,
        };
      }
      return false;
    },
  },
};
