import _ from '../misc/lodash';

/**
 * Mixin for filterable components
 */
export default {
  props: {
    object: {
      type: String,
      default: 'default',
    },
    fields: {
      type: [Object, Array],
      default: () => ({}),
    },
    pathToId: {
      type: Object,
      default: () => ({
        object: '$route',
        path: 'params.objectId',
      }),
    },
    queryParams: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    /**
     * Compute objectId
     * Path of objectId can be overide in configuration
     *
     * @return {String}
     */
    objectId() {
      if (typeof this.pathToId.object !== 'undefined'
        && typeof this.pathToId.path !== 'undefined') {
        return _.get(this[this.pathToId.object], this.pathToId.path);
      }
      return this.$route.params.objectId;
    },
    /**
     * Alias is not define in props to allow to compute fallback with object
     *
     * @return {String}
     */
    alias() {
      return this.$attrs.alias || this.object;
    },
    searchFields() {
      return this.fields.search || this.filtersProp || [];
    },
    readFields() {
      if (Array.isArray(this.fields)) {
        return this.fields;
      }
      return this.fields.read || [];
    },
    writeFields() {
      return this.fields.write || [];
    },
  },
};
